<template>
  <div
    class="px-4 lg:px-0 w-full lg:w-7/12 flex lg:block flex-col items-center justify-center"
  >
    <div class="md:w-6/12 lg:w-11/12 flex justify-between">
      <!--  <img
        class="h-24 lg:h-44"
        v-for="(image, i) in images"
        :key="i"
        :src="require('@/assets/images/' + image + '.png')"
        :alt="image"
      /> -->
    </div>
    <!--  <div class="mt-10 py-6 w-11/12 bg-white rounded-lg">
      <ul class="px-6 text-left border-l-4 border-primary list-disc">
        <li class="font-proximaLight">
          <span class="font-proximaMedium">Tasso No:</span> 5464657657
        </li>
        <li class="my-2 font-proximaLight">
          <span class="font-proximaMedium">Veterinarian:</span> Tierarzt
          Laskawy, Banschstr. 54B
        </li>
        <li class="font-proximaLight">
          <span class="font-proximaMedium">Last seen / scanned:</span> Storkower
          Str. S-Bhf, April 10, 2022 12. 22pm
        </li>
      </ul>
    </div> -->
    <div class="mt-6 w-11/12 hidden lg:block">
      <h5 class="mb-2 font-proximaMedium text-subheaderText text-left">
        Reported Lost Location:
      </h5>
      <div class="mapouter">
        <div class="gmap_canvas" v-if="data">
          <!-- <iframe
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            height="280px"
            class="w-full"
          ></iframe> -->
          <!--    <MapLocation :lat="data.location_lat" :lon="data.location_long" /> -->
        </div>
        <div class="w-full mt-4"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import MapLocation from "./MapLocation.vue";
export default {
  props: ["data"],
  components: {
    //MapLocation,
  },
  data() {
    return {
      images: ["dog-1", "dog-2", "dog-3"],
    };
  },
};
</script>

<style>
.mapouter {
  position: relative;
  text-align: right;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
}
</style>
