<template>
  <div class="w-full lg:w-5/12">
    <div class="flex flex-col items-center justify-center">
      <div
        class="w-full flex flex-row lg:flex-col items-center justify-evenly lg:justify-center"
      >
        <img
          class="rounded-2xl h-44 w-44"
          :src="picture"
          :alt="data.pet_info.callName"
        />

        <ul class="my-2 font-proximaMedium text-base text-left">
          <li class="my-1">{{ data.pet_info.callName }}</li>
          <li class="my-1">{{ data.pet_info.age }}</li>
          <li class="my-1">{{ data.pet_info.breeding_sex }}</li>
          <li class="my-1">{{ data.pet_info.height_weight }}</li>
        </ul>
      </div>
      <div class="px-16 my-8 lg:my-0 w-full flex items-center justify-between">
        <SubmitButton
          class="mr-1"
          paddingX="px-4 lg:px-8"
          title="Contact Owner"
          width="w-6/12"
          marginT="0"
          @click.native="submitcontactOwner"
        />
        <IconButton
          class="ml-1"
          width="w-6/12"
          paddingX="px-4 lg:px-8"
          component="ShareIcon"
          title="Share"
          background="bg-white"
          borderColor="border-primary"
          textCl="text-primary"
        />
      </div>
      <LostmodeRight class="block lg:hidden" />
      <div class="mt-2 px-10 lg:px-16 font-proximaMedium text-lg text-left">
        <!--  <h5 class="text-subheaderText">Details:</h5> -->
        <p class="font-proximaLight text-sm">
          <!--  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit turpis
          a felis platea risus. Turpis quam vulputate quis vulputate. Ornare
          hendrerit et scelerisque a. Eu tortor, ultrices aenean adipiscing
          turpis lorem nullam. Pellentesque elit posuere lacinia sed dignissim
          morbi nibh non. Suspendisse gravida morbi integer in adipiscing
          commodo in sit. Massa urna, nec commodo volutpat tincidunt
          pellentesque urna egestas. Mauris, in sit enim, est sodales ipsum
          pharetra. Mauris leo scelerisque in quis convallis vitae risus. -->
        </p>
      </div>
      <div class="w-9/12">
        <div class="mt-4 w-7/12">
          <!--  <div
            class="py-2 px-5 flex items-center justify-start cursor-pointer bg-primary text-white rounded-md hover:bg-white hover:text-primary"
            @click="toggleDropdown"
          >
            <img
              :src="
                require('@/assets/images/flags/' + selectedCountry.img + '.png')
              "
              :alt="selectedCountry.img"
            />
            <span class="pl-2">{{ selectedCountry.country }}</span>
          </div> -->
          <ul
            class="bg-white py-2 font-proximaLight rounded-md"
            v-if="dropdownCountry"
          >
            <!-- <li
              class="py-1 px-5 text-primary flex items-center justify-start cursor-pointer hover:bg-primary hover:text-white"
              v-for="(data, i) in countryList"
              :key="i"
              @click="selectCountry(data)"
            >
              <img
                :src="require('@/assets/images/flags/' + data.img + '.png')"
                :alt="data.img"
              />
              <span class="pl-2">{{ data.country }}</span>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="mt-6 w-11/12 block lg:hidden">
        <h5 class="mb-2 font-proximaMedium text-subheaderText text-left">
          Reported Lost Location:
        </h5>
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              height="280px"
              class="w-full"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import IconButton from "../buttons/IconButton.vue";
import SelectOptions from "../forms/SelectOptions.vue";
import LostmodeRight from "./LostmodeRight.vue";
import { lostPetcontactOwner } from "../../services/panicButtonService";
import { pictureByNameSpecies } from "../../methods/petMethods";

export default {
  props: ["data"],
  components: {
    SubmitButton,
    IconButton,
    SelectOptions,
    LostmodeRight,
  },
  data() {
    return {
      dropdownCountry: false,
      selectedCountry: { id: 1, country: "Spanish", img: "spain-flag" },
      countryList: [
        { id: 1, country: "Spanish", img: "spain-flag" },
        { id: 2, country: "English", img: "eng-flag" },
        { id: 3, country: "Dutch", img: "nl-flag" },
        { id: 4, country: "French", img: "fr-flag" },
      ],
      picture: null,
    };
  },
  created() {
    /*lost_info
:
id
:
"b39f5ee0-14a1-4e7e-8db3-cc48e70d54d2"
location_lat
:
"36.7329280000000000"
location_long
:
"3.0474240000000000"
lost_date
:
"2023-08-14T10:12:28.784787Z"
notification_status
:
false
userEmailAddress
:
"selma.isil@gmail.com"
[[Prototype]]
:
Object
pet_gallery
:
[]
pet_info
:
age
:
"0 years 4 months old"
breed
:
376
breeding_sex
:
"sterilized / Female"
callName
:
"qqqq"
height_weight
:
""
profile_picture
:
null
species
:
"cat" */
    //this.routePath = path;
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    const petData = JSON.parse(retrievedObject);

    /* try {
      this.picture = petData ? petPicture(petData) : null;
    } catch (error) {
      this.picture = null;
    }
    petData ? this.getPetProfile(petData.petID) : ""; */

    this.picture = this.data.pet_info.profile_picture
      ? this.data.pet_info.profile_picture
      : pictureByNameSpecies(this.data.pet_info);
  },
  methods: {
    toggleDropdown() {
      this.dropdownCountry = !this.dropdownCountry;
    },
    selectCountry(val) {
      this.selectedCountry = val;
      this.dropdownCountry = false;
    },
    async submitcontactOwner() {
      const req = {
        petID: "889e4266-198c-4625-84de-992acf951461", //this.data.lost_info.id,
        message: "",
        location_long: this.data.lost_info.location_long,
        location_lat: this.data.lost_info.location_lat,
        panic_lost_id: this.data.lost_info.id,
      };
      await lostPetcontactOwner(req);
    },
  },
};
</script>

<style></style>
