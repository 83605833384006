<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          titlemenu1="Community"
          :to1="'/communityoverview'"
          titlemenu2="Lost Pet"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 font-proximaMedium font-bold text-subheaderText text-3xl">
      Lost {{ pet.pet_info.species }}
    </div>
    <div class="mt-6 flex flex-col lg:flex-row lg:justify-between items-start">
      <LostmodeLeft :data="pet" />
      <LostmodeRight class="hidden lg:block" :data="pet" />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import LostmodeLeft from "./LostmodeLeft.vue";
import LostmodeRight from "./LostmodeRight.vue";

import DataserviceLogo from "../DataserviceLogo.vue";

import { nameOfPet } from "../../methods/petMethods";

import { mapActions, mapGetters } from "vuex";

export default {
  props: ["data"],
  components: {
    HeaderGlobal,
    LostmodeLeft,
    LostmodeRight,
    DataserviceLogo,
  },
  data() {
    return {
      pet: {},
    };
  },
  computed: {
    ...mapGetters({
      petaslost: "panicbutton/getPetaslost",
    }),

    name() {
      return this.data ? nameOfPet(this.data.pet_species) : "";
    },
  },

  /*lost_info
: 
id
: 
"b39f5ee0-14a1-4e7e-8db3-cc48e70d54d2"
location_lat
: 
"36.7329280000000000"
location_long
: 
"3.0474240000000000"
lost_date
: 
"2023-08-14T10:12:28.784787Z"
notification_status
: 
false
userEmailAddress
: 
"selma.isil@gmail.com"
[[Prototype]]
: 
Object
pet_gallery
: 
[]
pet_info
: 
age
: 
"0 years 4 months old"
breed
: 
376
breeding_sex
: 
"sterilized / Female"
callName
: 
"qqqq"
height_weight
: 
""
profile_picture
: 
null
species
: 
"cat" */

  created() {
    //this.getPetaslost(this.data.lost_id); //lost_id
    var retrievedObject = localStorage.getItem("petLost");
    this.pet = JSON.parse(retrievedObject);
  },

  methods: {
    ...mapActions({
      getPetaslost: "panicbutton/getPetaslost",
    }),
  },
};
</script>

<style></style>
