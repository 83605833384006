<template>
  <div class="my-6">
    <LostmodeSection :data="this.$route.params.data" />
  </div>
</template>

<script>
import LostmodeSection from "../components/lostmodeprofile/LostmodeSection.vue";

export default {
  components: {
    LostmodeSection,
  },
};
</script>